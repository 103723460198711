$logoPath: "../../images/muntra-logo.png";
$logoPathMobile: "../../images/muntra-logo.png";

$main-menu-item-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 230px;
$sub-menu-width-md: 230px;
$sub-menu-width-xs: 230px;

$navbar-height: 100px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.5rem;
$border-radius-rounded: 0.75rem;

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$theme-color-purple: #922c88;
$theme-color-blue: #1185d6;
$theme-color-green: #576a3d;
$theme-color-orange: #e2863b;
$theme-color-red: #880a1f;

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;

$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color: white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;

$button-text-color: #fff;

$theme-color-1: #49c4a1;
$theme-color-2: #dd9c02;
$theme-color-3: #849b65;
$theme-color-4: #494d43;
$theme-color-5: #d1c19a;
$theme-color-6: #7c8174;

$primary-color: #212121;
$secondary-color: #8f8f8f;
$muted-color: #909090;

:root {
  --theme-color-1: #{$theme-color-1};
  --theme-color-2: #{$theme-color-2};
  --theme-color-3: #{$theme-color-3};
  --theme-color-4: #{$theme-color-4};
  --theme-color-5: #{$theme-color-5};
  --theme-color-6: #{$theme-color-6};
  --primary-color: #{$primary-color};
  --foreground-color: #{$foreground-color};
  --separator-color: #{$separator-color};
  --separator-color-light: #{$separator-color-light};
}

html {
  width: 100%;
  height: 100%;
  background: $background-color;
}

#root {
  height: 100%;
}

body {
  font-family: "Roboto", arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  color: $primary-color;
  background: $background-color;

  &.background {
    height: 100%;

    main {
      margin: 0 !important;
      height: 100%;

      .container {
        height: 100%;
      }
    }
  }
}

.rtl * {
  text-align: right;
}

.fixed-background {
  background: url("../../images/background.jpg") no-repeat center fixed;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

h1 {
  font-size: 1.75rem;
  padding-bottom: 10px;
  display: inline-block;

  @include respond-below(sm) {
    font-size: 1.3rem;
    padding-top: 0.5rem;
  }
}

h2 {
  font-size: 1.4rem;

  @include respond-below(sm) {
    font-size: 1.1rem;
  }
}

h3 {
  font-size: 1.2rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h5 {
  font-size: 1.1rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h6 {
  font-size: 1rem;

  @include respond-below(sm) {
    font-size: 0.85rem;
  }
}

hr {
  border-top: 1px solid $separator-color;
}

::selection {
  background: lighten($theme-color-3, 20%);
}

::-moz-selection {
  background: lighten($theme-color-3, 20%);
}

.logo-single {
  width: 110px;
  height: 35px;
  background: url($logoPath) no-repeat;
  background-position: center center;
  display: inline-block;
  margin-bottom: 60px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.display-1 {
  @include respond-below(sm) {
    font-size: 2.8rem;
  }
}

a {
  color: $primary-color;
  transition: color $animation-time-short;

  &:hover,
  &:active {
    text-decoration: initial;
    color: $theme-color-1;
  }
}

p {
  font-size: 0.85rem;
  line-height: 1.3rem;
}

.text-white {
  color: $button-text-color !important;
}

.text-muted {
  color: $muted-color !important;
}

.text-primary {
  color: $theme-color-1 !important;
}

.text-secondary {
  color: $primary-color !important;
}

.separator {
  border-bottom: 1px solid $separator-color;
}

.rounded {
  border-radius: 50px !important;
}

.white {
  color: $button-text-color !important;
}

/* 02.Buttons */

button {
  color: $primary-color;
  outline: initial !important;
}

.btn {
  border-radius: 50px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.75rem 1.3rem 0.6rem 1.3rem;
  transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear;

  &:hover,
  &:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15),
      0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
  }
}

.btn.default {
  border-radius: $border-radius;
}

.btn-primary {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
    background-color: darken($theme-color-1, 8%);
    border-color: darken($theme-color-1, 8%);
  }
}

.dropdown-menu {
  font-size: 0.8rem;
  border-radius: $border-radius;
  background: $input-background;
  border-color: rgba($primary-color, 0.15);
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
  color: $primary-color;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: $background-color;
  color: $primary-color;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $button-text-color;
  text-decoration: none;
  background-color: $theme-color-1;
}

.dropdown-divider {
  border-color: $separator-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: darken($theme-color-1, 12%);
  border-color: darken($theme-color-1, 12%);
  color: $button-text-color;
}

.btn-secondary {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
    background-color: darken($theme-color-2, 8%);
    border-color: darken($theme-color-2, 8%);
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: darken($theme-color-2, 12%);
  border-color: darken($theme-color-2, 12%);
  color: $button-text-color;
}

.btn-primary.btn-primary-gradient {
  background: linear-gradient(
    to right,
    $theme-color-1,
    darken($theme-color-1, 15%)
  );

  &:hover {
    background: linear-gradient(
      to right,
      $theme-color-1,
      darken($theme-color-1, 8%)
    );
  }
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(
    to right,
    $theme-color-1,
    darken($theme-color-1, 15%)
  );
}

.btn-secondary-gradient {
  background: linear-gradient(
    to right,
    $theme-color-2,
    darken($theme-color-2, 15%)
  );

  &:hover {
    background: linear-gradient(
      to right,
      $theme-color-2,
      darken($theme-color-2, 8%)
    );
  }
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(
    to right,
    $theme-color-2,
    darken($theme-color-2, 15%)
  );
}

.btn-warning {
  background-color: $warning-color;
  border-color: $warning-color;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
  }
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
  &:hover {
    color: $foreground-color;
  }
}

.btn-light {
  color: $dark-btn-background;
  background-color: transparent;
  border-color: transparent;
  padding: 0.75rem 0.75rem 0.6rem 0.75rem;

  &:hover {
    color: $dark-btn-background;
    background-color: darken($light-btn-background, 1%);
    border-color: darken($light-btn-background, 1%);
  }
}

.btn-dark {
  color: $light-btn-background;
  background-color: $dark-btn-background;
  border-color: $dark-btn-background;

  &:hover {
    color: $light-btn-background;
    background-color: darken($dark-btn-background, 5%);
    border-color: darken($dark-btn-background, 5%);
  }
}

.btn-outline-dark {
  color: $dark-btn-background;
  border-color: $dark-btn-background;

  &:hover {
    color: $foreground-color;
    background-color: $dark-btn-background;
    border-color: $dark-btn-background;
  }
}

.btn-outline-white {
  color: $button-text-color;
  border-color: $button-text-color;
  background-color: initial;

  &:hover {
    color: $theme-color-1;
    background-color: $button-text-color;
  }
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem;
  font-size: 0.76rem;
  line-height: 1.5;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: $foreground-color;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $foreground-color;
}

.btn-link {
  color: $theme-color-blue;
  text-decoration: underline;
}

.btn-link:hover {
  cursor: pointer;
}

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms;

  .spinner,
  .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: $button-text-color;
  }

  .icon i {
    vertical-align: text-bottom;
    font-size: 18px;
  }

  .label {
    transition: opacity 500ms;
  }

  &.show-spinner {
    .label {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-success {
    .label {
      opacity: 0;
    }

    .icon.success {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-fail {
    .label {
      opacity: 0;
    }

    .icon.fail {
      opacity: 1;
      visibility: visible;
    }
  }

  &.btn-primary:disabled {
    opacity: 1;
    background: darken($theme-color-1, 10%);
    border-color: darken($theme-color-1, 10%);
  }

  &.btn-secondary:disabled {
    opacity: 1;
    border-color: darken($theme-color-2, 10%);
  }
}

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;

  &.large {
    width: 44px;
    height: 44px;
    font-size: 18px;
  }

  &.small-icon {
    font-size: 12px;
    line-height: 32px;
  }
}

.icon-view {
  margin-left: 5px;
  line-height: 1;

  background-color: transparent;
  background-image: linear-gradient(rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 0%;
  border: 0;
  border-radius: 50%;
  color: #000000;

  fill: rgba(0, 0, 0, 0.54);
  transition: background-size 0.13s;

  &:hover {
    background-size: 100% 100%;
    fill: rgb(0, 0, 0);
  }
}

.cancel-btn {
  border: none;
  background-color: transparent;
  margin: 0 0.75rem;
  font-weight: 600;
  color: var(--theme-color-4);

  &:hover:not([disabled]) {
    cursor: pointer;
    color: var(--theme-color-6);
  }

  &:disabled {
    color: var(--secondary-color);
  }
}
/* 03.Widths-Spacing */
.w-10 {
  width: 10% !important;
}

.w-media {
  @media (max-width: 840px) {
    max-width: 380px;
  }
}

.in-foc {
  border: 0;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 20px;

  &:focus {
    outline: 0;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #fff !important;
  }
}

.mb-5 {
  margin-bottom: 2rem !important;
}

/* 05.Badges */
.grey-badge,
.green-badge,
.light-green-badge,
.blue-badge,
.purple-budge,
.yellow-badge,
.orange-badge,
.red-badge {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  height: 22px;
  line-height: 22px;
  width: 22px;

  text-align: center;

  font-size: 11px;
  font-weight: 600;

  &:hover .tooltip {
    visibility: visible;
  }
}

.grey-badge {
  background-color: #d7dcde;
}

.green-badge {
  background-color: $success-color;
  color: #ffffff;
}

.light-green-badge {
  background-color: $theme-color-1;
  color: #ffffff;
}

.blue-badge {
  background-color: $theme-color-blue;
  color: #ffffff;
}

.purple-budge {
  background-color: $theme-color-purple;
  color: #ffffff;
}

.yellow-badge {
  background-color: #ffd600;
}

.orange-badge {
  background-color: #e2863b;
  color: #ffffff;
}

.red-badge {
  background-color: #f63d33b2;
  color: #ffffff;
}

.tooltip {
  visibility: hidden;

  background-color: #9c9c9c;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  white-space: nowrap;
  padding: 0.2rem 0.7rem;
  width: fit-content;

  position: absolute;
  top: -1.8rem;

  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

/* 06.Breadcrumb */
/* 07.Tabs */
.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: initial;
}

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: $theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: $theme-color-1;
}

.nav-tabs .nav-item:hover {
  cursor: pointer;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: $theme-color-1;
}

/* 09.Navbar*/
.navbar {
  background: $foreground-color;
  height: $navbar-height;
  padding: 1.5rem 0;
  @include depth(1);

  .menu-button-mobile {
    color: $secondary-color;
    text-align: center;
    margin-left: $main-margin-xs;
    svg {
      height: 12px;
      fill: $primary-color;
    }

    @include respond-below(sm) {
      width: 20px;
    }
  }

  .menu-button {
    color: $secondary-color;
    width: $main-menu-width;
    text-align: center;

    svg {
      height: 12px;
    }

    .main {
      fill: $primary-color;
      transition: fill $menu-collapse-time;
      width: 10px;
    }

    .sub {
      fill: $primary-color;
      transition: fill $menu-collapse-time;
    }

    &:hover {
      color: $theme-color-1;
    }

    @include respond-below(xl) {
      width: $main-menu-width-lg;
    }

    @include respond-below(lg) {
      width: $main-menu-width-md;
    }

    @include respond-below(sm) {
      width: 60px;
    }
  }

  .navbar-right {
    text-align: right;

    .user {
      margin-right: $main-margin;

      @include respond-below(xl) {
        margin-right: $main-margin-lg;
      }

      @include respond-below(lg) {
        margin-right: $main-margin-md;
      }

      @include respond-below(sm) {
        margin-right: $main-margin-xs;
      }
    }
  }

  .navbar-logo {
    padding-left: 30px;
    margin .logo {
      width: 100%;
      height: 100%;
      background: url($logoPath) no-repeat;
      background-position: center center;
    }

    .logo-mobile {
      width: 100%;
      height: 100%;
      background: url($logoPathMobile) no-repeat;
      background-position: center center;
      background-size: contain;
    }

    @include respond-below(sm) {
      width: 80px;
    }

    @include respond-below(xxs) {
      width: 20px;
    }
  }

  .search {
    position: relative;
    width: $sub-menu-width;
    border-radius: 20px;
    background: $background-color;

    input {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 1.75;
      font-size: 0.8rem;
      width: 93%;
      color: $primary-color;
    }

    .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: $separator-color;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -10px;
      right: 3px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: $theme-color-1;
      }
    }

    @include respond-below(lg) {
      width: $sub-menu-width * 0.7;
      input {
        width: 85%;
      }
    }

    @include respond-below(md) {
      width: calc($sub-menu-width/2);
      input {
        width: 85%;
      }
    }

    @include respond-below(sm) {
      width: 30px;
      height: 30px;
      background: initial;
      margin-left: 0.6rem;
      color: rgba($primary-color, 0.7);

      input {
        display: none;
      }

      .search-icon {
        font-size: 17px;
        width: 30px;
        height: 30px;
        bottom: -3px;
        right: 0;
        color: inherit;
      }
    }
  }

  .user {
    color: lighten($primary-color, 30%);
    position: relative;

    img {
      margin-left: 10px;
      border-radius: 30px;
      width: 40px;
    }

    @include respond-below(md) {
      .name {
        display: none;
      }
    }

    @include respond-below(sm) {
      img {
        width: 30px;
      }

      margin-left: initial;

      &:after {
        font-size: 11px;
        width: 14px;
        height: 14px;
        bottom: -3px;
        right: -3px;
      }
    }
  }

  @include respond-below(xl) {
    height: $navbar-height-lg;
  }

  @include respond-below(lg) {
    height: $navbar-height-md;
    padding: 1rem 0 1rem 0;
  }

  @include respond-below(sm) {
    height: $navbar-height-xs;
    padding: 10px 0;
  }
}

.sub-hidden .menu-button,
.menu-sub-hidden .menu-button {
  .sub {
    fill: $secondary-color;
  }
}

.menu-hidden .menu-button {
  .main,
  .sub {
    fill: $secondary-color;
  }
}

/* 10.Menu*/
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: $navbar-height;
  z-index: 4;
  height: calc(100% - #{$navbar-height});
  transition: border-radius $menu-collapse-time;

  .main-menu {
    width: $main-menu-width;
    height: calc(100% - #{$navbar-height});
    background: $foreground-color;
    z-index: 3;
    position: fixed;
    transition: transform $menu-collapse-time;
    padding-top: 10px;
    padding-bottom: 10px;
    left: 0;

    .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%;
      overflow: auto;

      .ps__thumb-y {
        right: 0;
      }
    }

    ul li {
      position: relative;

      span {
        text-align: center;
        padding: 0 10px;
        line-height: 14px;
      }

      a {
        height: $main-menu-item-height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: $theme-color-4;
        transition: color $menu-collapse-time;
        transition: background $menu-collapse-time;

        svg {
          fill: $theme-color-4;
        }

        &:hover,
        &:focus {
          color: $theme-color-1;
          svg {
            fill: $theme-color-1;
          }
        }
      }

      i {
        font-size: 32px;
        line-height: 42px;
      }

      &.active a {
        color: $theme-color-1;
        font-weight: 600;
        svg {
          fill: $theme-color-1;
        }
      }

      &.active:after {
        content: " ";
        background: $theme-color-1;
        border-radius: 10px;
        position: absolute;
        width: 6px;
        height: 90px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }

      &:last-of-type {
        a {
          border-bottom: initial;
        }
      }
    }

    @include respond-below(xl) {
      width: $main-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
      width: $main-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
      width: $main-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});

      ul li i {
        font-size: 28px;
        line-height: 38px;
      }

      ul li a {
        height: $main-menu-item-height-mobile;
      }

      ul li.active:after {
        width: 3px;
        height: 60px;
      }
    }
  }

  .sub-menu {
    width: $sub-menu-width;
    background: $foreground-color;
    z-index: 2;
    position: fixed;
    left: $main-menu-width;
    border-left: 1px solid $separator-color-light;
    @include depth(2);
    transition: transform $menu-collapse-time;
    height: calc(100% - #{$navbar-height});
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;

    .scroll {
      margin-right: unset;
      padding-right: unset;
      height: 100%;

      .ps__thumb-y {
        right: 0;
      }
    }

    ul {
      display: none;

      li {
        margin-bottom: 0;
        margin-left: 30px;
        position: relative;

        a {
          font-size: 13px;
          display: block;
          padding: 8px 0;
          margin-bottom: 10px;
        }

        i {
          font-size: 1.3em;
          margin-right: 10px;
          color: $secondary-color;
          vertical-align: middle;
          display: inline-block;
        }

        &.active i,
        &.active a {
          color: $theme-color-1;
        }

        &.active:after {
          content: " ";
          background: $theme-color-1;
          border-radius: 10px;
          position: absolute;
          width: 4px;
          height: 4px;
          top: 50%;
          transform: translateY(-50%);
          left: -16px;
        }

        span {
          vertical-align: middle;
          padding-top: 3px;
          display: inline-block;
        }
      }

      ul.third-level-menu {
        display: block !important;
        li {
          a,
          i {
            color: $primary-color;
          }
          a:hover,
          &.active i,
          &.active a {
            color: $theme-color-1;
          }

          &.active:after {
            left: -22px;
          }
        }
      }
    }

    @include respond-below(xl) {
      left: $main-menu-width-lg;
      width: $sub-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
      left: $main-menu-width-md;
      width: $sub-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
      left: $main-menu-width-xs;
      width: $sub-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});
    }
  }

  @include respond-below(xl) {
    padding-top: $navbar-height-lg;
    height: calc(100% - #{$navbar-height-lg});
  }

  @include respond-below(lg) {
    padding-top: $navbar-height-md;
    height: calc(100% - #{$navbar-height-md});
  }

  @include respond-below(sm) {
    padding-top: $navbar-height-xs;
    height: calc(100% - #{$navbar-height-xs});
    @include depth(1);
  }
}

.sub-hidden .sub-menu,
.menu-sub-hidden .sub-menu,
.menu-hidden .sub-menu {
  transform: translateX(-$sub-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$sub-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$sub-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$sub-menu-width-xs);
  }
}

.menu-hidden .main-menu {
  transform: translateX(-$main-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$main-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$main-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$main-menu-width-xs);
  }
}

.menu-hidden .sub-menu {
  transform: translateX(-$sub-menu-width - $main-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$sub-menu-width-lg - $main-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$sub-menu-width-md - $main-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs);
  }
}

.menu-mobile {
  .main-menu {
    transform: translateX(-$main-menu-width-xs);
  }

  .sub-menu {
    transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs - 50);
  }
}

.main-show-temporary {
  .main-menu {
    transform: translateX(0);
  }

  .sub-menu {
    transform: translateX(-$sub-menu-width);
  }

  @include respond-below(xl) {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width-lg);
    }
  }

  @include respond-below(lg) {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width-md);
    }
  }

  @include respond-below(sm) {
    .sub-menu {
      transform: translateX(-$sub-menu-width-xs);
    }
  }
}

.sub-show-temporary,
.menu-mobile.sub-show-temporary {
  .sub-menu {
    transform: translateX(0);
  }
}

/* 15.Main*/
main {
  margin-left: $sub-menu-width - 50;
  margin-top: $navbar-height;
  margin-right: $main-margin;
  margin-bottom: $main-margin - 20;
  transition: margin-left $menu-collapse-time;

  &.sub-hidden {
    margin-left: $main-menu-width + $main-margin;
  }

  .container-fluid {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
    @media (max-width: 994px) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media (max-width: 994px) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media (max-width: 700px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @include respond-below(xl) {
    margin-left: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
    margin-right: $main-margin-lg;
    margin-top: $main-margin-lg + $navbar-height-lg -10;
    margin-bottom: 30px;
  }

  @include respond-below(lg) {
    margin-left: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
    margin-right: $main-margin-md;
    margin-top: $main-margin-md + $navbar-height-md -10;
    margin-bottom: 20px;
  }

  @include respond-below(sm) {
    margin-left: $main-margin-xs + 30 !important;
    margin-right: $main-margin-xs !important;
    margin-top: $main-margin-xs + $navbar-height-xs !important;
    margin-bottom: 0;
  }

  @include respond-below(xs) {
    margin-bottom: 0;
  }
}

.sub-hidden main,
.menu-sub-hidden main,
.menu-hidden main {
  margin-left: $main-menu-width + $main-margin;
}

.menu-hidden main {
  margin-left: $main-margin;
}

@include respond-below(xl) {
  .sub-hidden main,
  .menu-sub-hidden main,
  .menu-hidden main {
    margin-left: $main-menu-width-lg + $main-margin-lg;
  }

  .menu-hidden main {
    margin-left: $main-margin-lg;
  }
}

@include respond-below(lg) {
  .sub-hidden main,
  .menu-sub-hidden main,
  .menu-hidden main {
    margin-left: $main-menu-width-md + $main-margin-md;
  }

  .menu-hidden main {
    margin-left: $main-margin-md;
  }
}

/* 20.Alerts */
.alert {
  border-radius: 0;
}

.alert-primary {
  color: $theme-color-1;
  background-color: rgba($theme-color-1, 0.2);
  border-color: rgba($theme-color-1, 0.1);
}

.alert-secondary {
  color: $theme-color-2;
  background-color: rgba($theme-color-2, 0.2);
  border-color: rgba($theme-color-2, 0.1);
}

.alert-success {
  color: $success-color;
  background-color: rgba($success-color, 0.2);
  border-color: rgba($success-color, 0.1);
}

.alert-info {
  color: $info-color;
  background-color: rgba($info-color, 0.2);
  border-color: rgba($info-color, 0.1);
}

.alert-warning {
  color: $warning-color;
  background-color: rgba($warning-color, 0.2);
  border-color: rgba($warning-color, 0.1);
}

.alert-danger {
  color: $error-color;
  background-color: rgba($error-color, 0.2);
  border-color: rgba($error-color, 0.1);
}

.alert-light {
  color: $light-btn-background;
  background-color: rgba($light-btn-background, 0.2);
  border-color: rgba($light-btn-background, 0.1);
}

.alert-dark {
  color: $dark-btn-background;
  background-color: rgba($dark-btn-background, 0.2);
  border-color: rgba($dark-btn-background, 0.1);
}

.alert-dismissible .close {
  text-shadow: initial;
}

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem;
}

.danger {
  color: red;
}

div[data-notify="container"] {
  padding: 18px;
}

.notification {
  background-color: #ffffff;

  margin-bottom: 5px;
  margin-top: 15px;

  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

/* 21.Forms */
.av-invalid .av-label,
.form-group.text-danger,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: $primary-color !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: $separator-color;
  background: initial;
}

.form-inline {
  .form-group {
    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.form-check-label,
.custom-control-label {
  line-height: 24px !important;
}

.form-control:focus {
  background: $input-background;
  border-color: rgba($theme-color-1, 0.6);
  color: $primary-color;
}

select.form-control:not([size]):not([multiple]) {
  height: initial;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba($primary-color, 0.25) !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='" + encodecolor(
      $button-text-color
    ) + "' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='" + encodecolor(
      $button-text-color
    ) + "' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='" + encodecolor(
      $button-text-color
    ) + "'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: initial;
}

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
}

.custom-control-label::before {
  border: 1px solid $muted-color;
  background: initial;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: $theme-color-1;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
  border: 1px solid $theme-color-1;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
}

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important;
  outline: initial !important;
}

.custom-control-label::before {
  top: 0.2rem;
}

.custom-control-label::after {
  top: 0.2rem;
}

.btn.rotate i {
  transform: rotate(180deg);
}

.btn .custom-control-label::before {
  border: 1px solid $button-text-color;
}

.btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid $button-text-color;
}

.input-group-text {
  border-radius: $border-radius;
  background-color: lighten($input-background, 5%);
  border-color: $separator-color;
  color: $primary-color;
  font-size: 0.8rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.form-group {
  position: relative;
}

.form-control {
  border-radius: $border-radius;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.75rem;
  line-height: 1.5;
  border: 1px solid $separator-color;
  background: $input-background;
  color: $primary-color;
  height: initial;
}

@keyframes autofill {
  to {
    color: $primary-color;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
  -webkit-text-fill-color: $primary-color !important;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: $border-radius;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: $border-radius;
}

.custom-select {
  border-radius: $border-radius;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important;
}

/* 22.Slider(Range) */

/* 23.Navs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: $foreground-color;
  background-color: $theme-color-1;
}

.nav-pills .nav-link {
  border-radius: 50px;
}

.nav-link {
  padding: 0.8rem 1.5rem;

  @include respond-below(sm) {
    padding: 0.5rem 0.5rem;
  }

  &.active {
    color: $theme-color-1;
    font-weight: 600;
  }
}

/* 28.Spinner */
.btn-multiple-state {
  .spinner {
    width: 36px;
    text-align: center;
  }

  .spinner > span {
    width: 6px;
    height: 6px;
    background-color: $button-text-color;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@media print {
  body {
    background: $foreground-color;
    height: 100%;
  }

  main {
    margin: 0 !important;
  }

  .navbar,
  .sidebar,
  .theme-colors {
    display: none;
  }

  main .container-fluid .row:not(.invoice) {
    display: none;
  }

  .invoice {
    width: 100%;
    max-width: 100%;

    &.row {
      margin: 0;
    }

    [class*="col"] {
      padding: 0;
    }

    .invoice-contents {
      width: 100%;
      max-width: 100% !important;
      height: 1370px !important;
    }
  }
}

.modal {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 1.75rem;
  }

  .modal-header {
    border-bottom: 1px solid $separator-color;
  }

  .modal-footer {
    border-top: 1px solid $separator-color;
  }

  .close {
    color: $primary-color;
    text-shadow: initial;
  }
}

.modal-content {
  border: initial;
  background: $foreground-color;
  border-radius: 15px;
}

.modal-dialog.modal-dialog-custom {
  margin: auto;
  text-align: center;
  min-width: 25.75rem;
}

.modal.fade.show {
  display: flex !important;
}

.auth-card {
  display: flex;
  flex-direction: row;
  @include depth(1);

  .image-side {
    width: 40%;
    background: url("../../images/login-balloon.jpg") no-repeat center top;
    background-size: cover;
    padding: 80px 40px;

    .h3 {
      line-height: 0.8rem;
    }
  }

  .form-side {
    width: 60%;
    padding: 80px;
  }

  @include respond-below(md) {
    flex-direction: column;

    .image-side {
      width: 100%;
      padding: 60px;
    }

    .form-side {
      width: 100%;
      padding: 60px;
    }
  }

  @include respond-below(sm) {
    p.h2 {
      font-size: 1.6rem;
    }
  }

  @include respond-below(xs) {
    flex-direction: column;

    .image-side {
      padding: 35px 30px;
    }

    .form-side {
      padding: 35px 30px;
    }

    .logo-single {
      margin-bottom: 20px;
    }

    p.h2 {
      font-size: 1.4rem;
    }
  }
}

.has-float-label {
  display: block;
  position: relative;
}

.has-float-label label::after,
.has-float-label > span::after {
  background: $foreground-color !important;
}

.has-top-label label,
.has-top-label > span,
.has-float-label label,
.has-float-label > span {
  color: rgba($primary-color, 0.7);
}

.has-float-label label,
.has-float-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}

.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::placeholder {
  opacity: 1;
}

.has-float-label
  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-float-label
  .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.input-group .has-float-label {
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-group .has-float-label .form-control {
  width: 100%;
}

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem !important;
}

.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-top-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::placeholder {
  opacity: 1;
}

.has-top-label
  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-top-label
  .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 150%;
  opacity: 0.5;
  top: 0.3em;
}

.input-group .has-top-label {
  flex-grow: 1;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-group .has-top-label .form-control {
  width: 100%;
}

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  min-height: calc(3.3rem + 2px) !important;
}

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba($theme-color-1, 0.2);
  border-radius: 50%;
  border-top-color: $theme-color-1;
  animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.card.react-contextmenu--visible,
.card.active {
  @include depth(2);
}

.table th,
.table td {
  border-color: $separator-color-light !important;
}

.table .thead-light th {
  background-color: $separator-color-light !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $separator-color-light !important;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 40px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.4em solid rgba(0, 0, 0, 0.2);
  border-right: 0.4em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.4em solid rgba(0, 0, 0, 0.2);
  border-left: 0.4em solid #484848;
  transform: translateZ(0);
  animation: load8 0.6s infinite linear;
}
.small-loader {
  margin: 0;
  font-size: 5px;
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table-row {
  td {
    svg {
      transition: opacity 150ms, transform 150ms;
      cursor: pointer;
    }
  }
}

.table-row:not(:hover) {
  td {
    svg {
      opacity: 0;
      transform: translateX(101%);
      z-index: -1;
    }
  }
}

.table-row:hover {
  td {
    svg {
      opacity: 1;
      transform: translateX(0);
      z-index: 1;
    }
  }
}

.user-btn {
  display: flex;
  align-items: center;
  .user-icon {
    background: #f8f8f8;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
}

.text-container {
  text-align: right;
  line-height: 16px;
}

.hover:hover {
  cursor: pointer;
}

.skeleton-box {
  height: 100%;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#dddbdd, 0) 0,
      rgba(#dddbdd, 0.2) 20%,
      rgba(#dddbdd, 0.3) 80%,
      rgba(#dddbdd, 0)
    );
    animation: shimmer 1.5s infinite;
    content: "";
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.section-loader {
  width: 100%;
  height: 170px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.muntra-logo {
  height: 50px;
}

.version-container {
  position: absolute;
  left: 32px;
  bottom: 4px;
  color: $secondary-color;
  font-size: 11px;
}

.fallback {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100%;
}

.custom-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

table tr td input[type="checkbox" i] {
  margin: 3px 3px 3px 4px;
}
table tr:last-child td {
  border-bottom: 1px solid $separator-color-light;
}
table tr th input[type="checkbox" i] {
  margin: 0 3px 0 4px;
}

.flexJustCenter {
  display: flex;
  justify-content: center;
}

.paginationContent,
.paginationContentHolder {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 0;
  margin-top: 0;
  width: max-content;
}

span.paginationContent {
  border: 1px solid rgb(216, 220, 222);
  box-shadow: none;
  color: #2f3941;
  display: block;
  font-size: 15px;
  line-height: 34px;
  min-width: 35px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.paginationContentHolder > div:not(:first-child) > span.paginationContent {
  border-left-width: 0px;
}

span.paginationContent:hover {
  background-color: #f4f4f4;
}

.activePaginationItem,
.activePaginationItem:hover,
.activePaginationItem span.paginationContent:hover {
  background-color: rgb(248, 249, 249);
  border-color: #ced4da;
  cursor: default;
}
